<template>
  <v-card>
    <center>
      <h3>
        CALCULADORA VOLUMÉTRICA
      </h3>
    </center>
    <v-card-text>
      <v-container>
        <v-form ref="calcularPesoFrom">
          <div class="row">
            <div class="col-4">
              <v-autocomplete
                dense
                clearable
                :items="unidadesLongitud"
                label="Longitud"
                :rules="[(v) => !!v || 'Cantidad Requerida.']"
                v-model="fromMedidas.idLongitud"
              >
              </v-autocomplete>
            </div>

            <div class="col-4">
              <v-autocomplete
                dense
                clearable
                :items="unidadesPeso"
                label="Peso"
                :rules="[(v) => !!v || 'Cantidad Requerida.']"
                v-model="fromMedidas.idpeso"
              >
              </v-autocomplete>
            </div>
            <div class="col-4">
              <v-text-field
                type="number"
                min="1"
                label="N° Bultos"
                required
                dense
                :rules="[(v) => !!v || 'Cantidad Requerida.']"
                v-model="fromMedidas.bultos"
              ></v-text-field>
            </div>
            <div class="col-12 divisorCalculadora elevation-1">
              MEDIDAS POR CADA BULTO
            </div>
            <div class="col-3">
              <v-text-field
                type="number"
                min="1"
                label="Largo"
                required
                dense
                clearable
                :rules="[(v) => !!v || 'Largo Requerida.']"
                v-model="fromMedidas.largo"
              ></v-text-field>
            </div>
            <div class="col-3">
              <v-text-field
                type="number"
                min="1"
                label="Ancho"
                required
                dense
                clearable
                :rules="[(v) => !!v || 'Ancho Requerido.']"
                v-model="fromMedidas.ancho"
              ></v-text-field>
            </div>
            <div class="col-3">
              <v-text-field
                type="number"
                min="1"
                label="Alto"
                required
                dense
                clearable
                :rules="[(v) => !!v || 'Alto Requerida.']"
                v-model="fromMedidas.alto"
              ></v-text-field>
            </div>

            <div class="col-3">
              <v-text-field
                type="number"
                min="0"
                step="0.01"
                label="Peso x Bulto"
                required
                dense
                clearable
                :rules="[(v) => !!v || 'Peso Requerido.']"
                v-model="fromMedidas.peso"
              ></v-text-field>
            </div>
            <v-flex text-right>
              <v-btn
                dense
                small
                color="#fb9514"
                style="color: #ffffff !important;"
                class="me-2 text-white"
                @click="calcular()"
              >
                <b>Calcular</b>
              </v-btn>
            </v-flex>
            <span class="mt-2 ma-1 pa-1">
              Nota: Los valores de Peso y Volumen serán convertidos
              automáticamente a Kilogramos y Metros respectivamente.
            </span>
            <div class="col-12">
              <v-simple-table dense class="elevation-1">
                <thead class="success white--text">
                  <tr>
                    <td>#</td>
                    <td>Uni(s)</td>
                    <td>Peso (Kg.)</td>
                    <td>Volumen (m <sup>3</sup>)</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(medida, i) in tblMedida" :key="i">
                    <td>{{ i + 1 }}</td>
                    <td>{{ medida.uni }}</td>
                    <td>{{ medida.peso }}</td>
                    <td>{{ medida.volumen }}</td>
                    <td>
                      <v-btn
                        dense
                        color="orange darken-4"
                        class="ma-0 pa-0"
                        small
                      >
                        <v-icon color="white" @click="borrarFila(i)"
                          >mdi-delete-circle</v-icon
                        >
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ tblTotal.descripcion }}</td>
                    <td>{{ tblTotal.uni }}</td>
                    <td>{{ tblTotal.peso }}</td>
                    <td>{{ tblTotal.volumen }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </div>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success white--text" small @click="aceptar">
        Aceptar
      </v-btn>
      <v-btn color="red white--text" small>
        Cerrar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "calculadorComponet",
  data() {
    return {
      unidadesLongitud: [
        { text: "Centimetros", value: 1, factor: 0.01 },
        { text: "Metros", value: 2, factor: 1 },
        { text: "Pulgadas", value: 3, factor: 0.0254 },
        { text: "Pie", value: 4, factor: 0.3048 },
        { text: "Milímetros", value: 5, factor: 0.001 },
      ],
      unidadesPeso: [
        { text: "Kilo", value: 1, factor: 1 },
        { text: "Libra", value: 2, factor: 2.205 },
        { text: "Tonelada", value: 3, factor: 1000 },
      ],
      fromMedidas: {
        idLongitud: null,
        idpeso: null,
        bultos: null,
        largo: null,
        ancho: null,
        alto: null,
        peso: null,
      },
      tblMedida: [],
      tblTotal: {
        descripcion: null,
        uni: null,
        peso: null,
        volumen: null,
      },
    };
  },
  methods: {
    ...mapMutations(["setCalculadorFlag"]),
    calcular() {
      if (this.$refs.calcularPesoFrom.validate()) {
        // bultos

        // factores
        let factLongitud = this.unidadesLongitud.filter(
          (v) => v.value == this.fromMedidas.idLongitud
        )[0].factor;

        let facPeso = this.unidadesPeso.filter(
          (v) => v.value == this.fromMedidas.idpeso
        )[0].factor;

        // volumen

        let volumen =
          this.fromMedidas.largo *
          this.fromMedidas.ancho *
          this.fromMedidas.alto *
          Math.pow(factLongitud, 3);
        // peso
        let peso = facPeso * this.fromMedidas.peso * this.fromMedidas.bultos;

        this.tblMedida.push({
          uni: this.fromMedidas.bultos,
          peso: Math.round(peso * 100) / 100,
          volumen: (this.fromMedidas.bultos * Math.round(volumen * 100)) / 100,
        });
        this.fromMedidas ==
          {
            idLongitud: null,
            idpeso: null,
            bultos: null,
            largo: null,
            ancho: null,
            alto: null,
            peso: null,
          };
        this.$refs.calcularPesoFrom.reset();
        this.total();
      }
    },

    total() {
      this.tblTotal = null;
      let uni = 0;
      let peso = 0;
      let volumen = 0;
      this.tblMedida.forEach((element) => {
        uni += parseInt(element.uni);
        peso += parseInt(element.peso);
        volumen += parseInt(element.volumen);
      });
      this.tblTotal = {
        descripcion: "Total:",
        uni: uni,
        peso: peso,
        volumen: volumen,
      };
    },

    borrarFila(i) {
      this.tblMedida.splice(i, 1);
      this.total();
    },

    aceptar() {
      let data = {
        bultos: this.tblTotal.uni,
        metroscc: this.tblTotal.volumen,
        kg: this.tblTotal.peso,
      };
      this.setCalculadorFlag(data);
    },
  },
  computed: {},
};
</script>

<style>
.divisorCalculadora {
  background: #656565 !important;
  color: white !important;
  border-radius: 5px;
  text-align: center;
  padding: 0;
  margin: 0;
}
</style>
